import React from 'react';
import isExternalUrl, { addTrailingSlash } from '../../utils';
import * as Styled from './slpHeaderWithProductsNameStyles';

export default function slpHeaderWithProductsName({ sectionData }) {
  return (
    <Styled.HeadContainer>
      <Styled.Head>
        <Styled.LinkStyle href={isExternalUrl(sectionData?.path) ? sectionData?.path :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + sectionData?.path)} target="_blank">
          <Styled.HeadLogo
            src={sectionData?.image?.file?.url}
            alt={sectionData?.image?.file?.fileName}
            title={sectionData?.image?.file?.fileName}
          />
        </Styled.LinkStyle>
        <div style={{ clear: 'both' }}></div>
      </Styled.Head>
    </Styled.HeadContainer>
  );
}
